import React from 'react';
import { css } from 'glamor';
import { Link as GatsbyLink } from 'gatsby';
import { colors } from '../style';
import Loading from './svg/AnimatedLoading';

export const View = React.forwardRef((props, ref) => {
  const { style, ...restProps } = props;
  return (
    <div
      {...restProps}
      ref={ref}
      className={`view ${props.className || ''} ${css(props.style)}`}
    />
  );
});

export const Text = React.forwardRef((props, ref) => {
  const { style, ...restProps } = props;
  return (
    <span
      {...restProps}
      ref={ref}
      className={`${props.className || ''} ${css(props.style)}`}
    />
  );
});

export const Link = React.forwardRef((props, ref) => {
  const { color, to, external, linkStyle, style, ...restProps } = props;
  return (
    <Text style={props.style}>
      {external ? (
        <a style={linkStyle} ref={ref} href={to} {...restProps} />
      ) : (
        <GatsbyLink ref={ref} to={to} {...restProps} style={linkStyle} />
      )}
    </Text>
  );
});

export const Button = React.forwardRef((props, ref) => {
  const { style, primary, ...nativeProps } = props;
  return (
    <button
      ref={ref}
      {...nativeProps}
      {...css([
        {
          border: 'none',
          padding: '6px 15px',
          borderRadius: 4,
          backgroundColor: primary ? '#008280' : 'white',
          borderColor: colors.p4,
          borderWidth: primary ? 0 : 1,
          borderStyle: 'solid',
          color: primary ? '#ffffff' : colors.p4,
          cursor: 'pointer'
        },
        props.style
      ])}
    />
  );
});

export const ButtonWithLoading = React.forwardRef((props, ref) => {
  let { loading, loadingColor, children, ...buttonProps } = props;
  return (
    <Button
      {...buttonProps}
      style={[buttonProps.style, { position: 'relative' }]}
    >
      {loading && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loading color={loadingColor} style={{ width: 20, height: 20 }} />
        </View>
      )}
      <Text style={{ opacity: loading ? 0 : 1 }}>{children}</Text>
    </Button>
  );
});

export const ButtonLink = React.forwardRef((props, ref) => {
  let { to, style, buttonStyle, ...buttonProps } = props;
  return (
    <GatsbyLink
      ref={ref}
      to={to}
      {...css({ display: 'flex' }, style)}
      className="no-underline"
    >
      <Button {...buttonProps} style={[{ flex: 1 }, buttonStyle]} />
    </GatsbyLink>
  );
});

export function ActionButton({ buttonStyle, text, ...props }) {
  return (
    <ButtonLink
      {...props}
      buttonStyle={[
        {
          backgroundColor: '#008280',
          borderColor: 'transparent',
          color: '#ffffff'
        },
        buttonStyle
      ]}
    >
      {text}
    </ButtonLink>
  );
}

export function HeaderText({ textSize = 'text-3xl', className, ...props }) {
  return (
    <Text
      className={`font-display ${textSize} leading-tight ${className}`}
      {...props}
    />
  );
}

export function Title({ title, style, ...props }) {
  return (
    <Text
      className="text-gray-700"
      {...props}
      {...css([
        {
          fontSize: 30,
          marginTop: 80,
          alignSelf: 'center'
        },
        style
      ])}
    >
      {title}
    </Text>
  );
}

export function Subtitle(props) {
  return (
    <Title
      {...props}
      style={[{ fontSize: 30, marginTop: 80, marginBottom: 30 }, props.style]}
    />
  );
}

export function P({ children, style }) {
  return <div {...css([{ margin: '1.5em 0' }, style])}>{children}</div>;
}

export function Screenshot({ src, label, labelStyle, style, width }) {
  return (
    <View
      style={[
        {
          margin: '50px 0 30px 0',
          maxWidth: width,
          alignSelf: 'center',
          '& img': {
            boxShadow: '0 1px 2px rgba(0, 0, 0, .35)',
            borderRadius: 5
          }
        },
        style
      ]}
    >
      <img src={src} />
      <Text
        style={[
          {
            color: colors.n6,
            fontSize: 13,
            textAlign: 'center',
            marginTop: 5
          },
          labelStyle
        ]}
      >
        {label}
      </Text>
    </View>
  );
}
