import React from 'react';
import { View, Link, Button, ActionButton } from './common';
import { Link as GatsbyLink } from 'gatsby';
import logo from '../images/logotype.png';
import { colors } from '../style';
import Content, { TINY } from './content';
import DotsHorizontalTriple from '../images/svg/DotsHorizontalTriple';

const links = [
  { title: 'Moving to open-source', url: '/' },
  { title: 'Account', url: '/account/' }
];

class Header extends React.Component {
  state = { menuOpen: false };

  render() {
    return (
      <Content style={{ [TINY]: { width: '100%', padding: '0 15px' } }}>
        <View
          className="header"
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 30,
            zIndex: 1,
            [TINY]: { position: 'static' }
          }}
        >
          <GatsbyLink
            to="/"
            style={{
              color: '#272630',
              textDecoration: 'none',
              fontSize: 0
            }}
          >
            <img src={logo} width={110} />
          </GatsbyLink>

          <View style={{ flex: 1 }} />

          <View
            style={{
              display: 'none',
              [TINY]: { display: 'block' }
            }}
          >
            <View
              style={{ width: 100, alignItems: 'flex-end' }}
              onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
            >
              <DotsHorizontalTriple
                width={20}
                className="text-red-500"
                style={{ color: '#805AD5' }}
              />
            </View>

            {this.state.menuOpen && (
              <View
                className="absolute bg-white shadow rounded-lg px-2"
                style={{
                  top: 40,
                  right: 0,
                  width: 250
                }}
              >
                {links.map(link => (
                  <Link
                    external={link.external}
                    key={link.url}
                    to={link.url}
                    style={{
                      '&:hover': { borderBottomColor: 'transparent' }
                    }}
                  className="border-b border-gray-200"
                    linkStyle={{ display: 'block', padding: 15 }}
                  >
                    {link.title}
                  </Link>
                ))}
              </View>
            )}
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              [TINY]: { display: 'none' }
            }}
          >
            {links.map(link => (
              <Link
                external={link.external}
                key={link.url}
                to={link.url}
                color={colors.n1}
                style={{ marginLeft: 30 }}
              >
                {link.title}
              </Link>
            ))}
          </View>
        </View>
      </Content>
    );
  }
}

export default Header;
