import React from 'react';
import { View } from './common';

export const TINY = '@media(max-width: 400px)';
export const SMALL = '@media(max-width: 690px)';
export const MEDIUM = '@media(max-width: 850px)';
export const LARGE = '@media(max-width: 1024px)';

const containerStyles = {
  width: 950,
  [LARGE]: { width: 800 },
  [MEDIUM]: { width: 650 },
  [SMALL]: { width: '100%', paddingLeft: 15, paddingRight: 15 }
};

export default ({ style, className, overrideStyles, children }) => {
  return (
    <View
      className={className}
      style={[
        { marginLeft: 'auto', marginRight: 'auto' },
        overrideStyles ? null : containerStyles,
        style
      ]}
    >
      {children}
    </View>
  );
};
