import React from 'react';
import { View, Text, Link } from './common';
import Content from './content';
import { colors } from '../style';
import Logo from '../images/logo-light.svg';

function FooterSection({ title, children }) {
  return (
    <View
      style={{ '& li': { margin: 0, marginBottom: 4 } }}
      className="mt-10 md:mt-0 md:ml-20 text-gray-600"
    >
      <Text className="mb-4">
        <strong>{title}</strong>
      </Text>
      {children}
    </View>
  );
}

export default () => (
  <Content className="footer self-start pb-12 md:pb-32 mt-48">
    <View className="flex-col md:flex-row justify-between items-start">
      <View>
        <div>
          <img src={Logo} style={{ width: 20 }} />
        </div>

        <div className="text-sm mt-8">
          <div className="mb-1">
            <Text style={{ color: colors.n5, marginTop: 20 }}>
              &copy; 2020 Shift Reset LLC
            </Text>
          </div>
          <Link to="/terms-and-privacy" color={colors.n5}>
            Terms and Privacy
          </Link>
        </div>
      </View>

      <View className="flex-col md:flex-row">
        <FooterSection title="Contact">
          <ul>
            <li>
              <Link
                external
                to="mailto:help@actualbudget.com"
                color={colors.n1}
              >
                Email
              </Link>
            </li>
          </ul>
        </FooterSection>
      </View>
    </View>
  </Content>
);
